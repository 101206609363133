import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/company',
        name: 'CompanyView',
        component: () => import(/* webpackChunkName: "projects" */ '@/views/CompanyView.vue')
    },
    {
        path: '/projects',
        name: 'ProjectListing',
        component: () => import(/* webpackChunkName: "projects" */ '@/views/ProjectListingView.vue')
    },
    {
        path: '/calculation',
        name: 'ProjectCalculation',
        component: () => import(/* webpackChunkName: "calculation" */ '@/views/ProjectCalculationView.vue'),
        props: true
    },
    {
        path: '/configurator',
        name: 'ProjectConfigurator',
        component: () => import(/* webpackChunkName: "configurator" */ '@/views/ProjectConfiguratorView.vue'),
        children: [
            {
                path: 'categories',
                component: () => import(/* webpackChunkName: "configuratorCategories" */ '@/components/project-configurator/CategorySelection'),
            },
            {
                path: 'elements',
                component: () => import(/* webpackChunkName: "configuratorElements" */ '@/components/project-configurator/ElementSelection')
            },
        ]
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: () => import(/* webpackChunkName: "checkout" */ '@/views/CheckoutView.vue'),
        props: true
    },
    {
        path: '/info',
        name: 'Info',
        component: () => import(/* webpackChunkName: "account" */ '@/views/InfoView')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
