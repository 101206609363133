<template>
  <v-dialog v-model="showModalRegister" max-width="560">
    <v-card id="modalRegister" style="background-color: white;" :class="{'registered': registered}">
      <a class="modal-close d-inline-flex" @click="setState(false)">
        Schließen
        <i class="fa-solid fa-xmark"/>
      </a>
      <v-card-text :class="{'pb-0': registered}" class="pt-10 d-flex flex-column">
        <h1 :class="{'mb-5': !registered}">
          <span v-if="!registered">Registrieren</span>
          <span v-else>Vielen Dank</span>
        </h1>
        <h2 v-if="registered" class="mb-16">
          Sie haben sich erfolgreich registriert.
        </h2>
        <v-alert
            v-if="errorRegister && !registered"
            class="mb-5"
            density="compact"
            type="error"
            variant="tonal"
        >
          {{ errorRegister }}
        </v-alert>
        <v-form
            v-if="!registered"
            v-model="registerForm.valid"
            :disabled="loadingRegister"
            class="w-100"
            lazy-validation
            @submit.prevent.stop="registerUser"
        >
          <v-row no-gutters>
            <v-col class="p-0" cols="12">
              <v-text-field
                  v-model="registerForm.email"
                  :rules="[rules.required, rules.email]"
                  autocomplete="email"
                  color="#0099ff"
                  density="comfortable"
                  label="E-Mail"
                  type="email"
                  variant="outlined"
              />
            </v-col>
            <v-col class="p-0 password position-relative" cols="12">
              <v-text-field
                  v-model="registerForm.password"
                  :rules="[rules.required, rules.password, rules.passwordMin, rules.passwordMax]"
                  :type="showPassword ? 'text' : 'password'"
                  autocomplete="new-password"
                  color="#0099ff"
                  counter="20"
                  density="comfortable"
                  label="Passwort"
                  variant="outlined"
              />
              <div
                  class="show-password position-absolute"
                  @mousedown="showPassword = true"
                  @mouseleave="showPassword = false"
                  @mouseup="showPassword = false"
              >
              <span v-show="showPassword">
                <i class="fa-solid fa-eye"/>
              </span>
                <span v-show="!showPassword">
                <i class="fa-solid fa-eye-slash"/>
              </span>
              </div>
            </v-col>
            <v-col class="p-0 password position-relative" cols="12">
              <v-text-field
                  v-model="registerForm.passwordConfirmation"
                  :rules="[rules.required, rules.confirm]"
                  :type="showPassword ? 'text' : 'password'"
                  autocomplete="new-password"
                  color="#0099ff"
                  density="comfortable"
                  label="Passwort wiederholen"
                  variant="outlined"
              />
            </v-col>
            <v-col class="p-0 mb-5 d-flex justify-end align-center" cols="12">
              <v-btn
                  :disabled="loadingRegister"
                  :loading="loadingRegister"
                  class="btn-primary"
                  type="submit"
              >
                Registrieren
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <p v-if="!registered" class="agreement text-left text-subtitle-2">
          Mit der Erstellung eines Accounts stimmen Sie unseren <a href="" @click.stop.prevent="showLegalConditions">AGB's</a>
          und der <a
            href="" @click.stop.prevent="showLegalPrivacy">Datenschutzerklärung</a> zu.
        </p>
        <div v-else class="confirmed pe-5 d-flex justify-center">
          <v-img :src="require('@/assets/undraw_confirmed.svg')"/>
        </div>
      </v-card-text>
    </v-card>
    <ModalLogin ref="ModalLogin"/>
    <Legal ref="Legal"/>
  </v-dialog>
</template>

<script>
import ModalLogin from "@/components/modal/Login";
import Legal from "@/components/modal/Legal";
import {mapGetters} from "vuex";

export default {
  name: 'ModalRegister',
  components: {
    ModalLogin,
    Legal
  },
  computed: {
    ...mapGetters([
      'loadingRegister',
      'registered',
      'errorRegister'
    ])
  },
  data() {
    return {
      showModalRegister: false,
      registerForm: {
        valid: false,
        email: '',
        password: '',
        passwordConfirmation: ''
      },
      showPassword: false,
      rules: {
        required: value => !!value || 'Pflichtfeld.',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Die E-Mail ist ungültig.'
        },
        password: value => {
          const pattern = /^(?=.*[a-z])(?=.*[A-Za-z])(?=.*[0-9])(?=.*[~!@#$%^&*\-_+=|";[\]{}()<>,./?\\])/;
          return pattern.test(value) || 'Das Passwort muss Buchstaben, Ziffern und Sonderzeichen(~!@#$%^&*-_+=|";[]{}()<>,./?\\) enthalten.';
        },
        passwordMin: value => value.length >= 9 || 'Das Passwort muss mindestens 9 Zeichen lang sein.',
        passwordMax: value => value.length <= 20 || 'Das Passwort darf maximal 20 Zeichen lang sein.',
        confirm: value => (value === this.registerForm.password) && this.registerForm.password !== '' || 'Die Passwörter müssen übereinstimmen.'
      }
    }
  },
  methods: {
    setState(state) {
      this.showModalRegister = state;
    },
    showLegalConditions() {
      this.$refs.Legal.show({
        title: 'Nutzungsbedingungen',
        component: 'conditions'
      });
    },
    showLegalPrivacy() {
      this.$refs.Legal.show({
        title: 'Datenschutzerklärung',
        component: 'privacy'
      });
    },
    registerUser() {
      if (this.registerForm.valid) {
        this.$store.dispatch('register', this.registerForm)
      }
    }
  },
  watch: {
    showModalRegister(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.registerForm = {
          valid: false,
          email: '',
          password: '',
          passwordConfirmation: ''
        };
        this.$store.dispatch('resetErrorRegister');
        this.$store.dispatch('resetRegistered');
      }
    }
  }
}
</script>