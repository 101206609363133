<template>
  <div id="accountEvents">
    <div v-if="loadingEvents" class="my-16 py-16 d-flex justify-center">
      <v-progress-circular
          color="#0099ff"
          indeterminate
      />
    </div>

    <div v-else-if="events.length === 0" class="pa-3 d-flex justify-center text-center">
      <v-card elevation="5" class="px-15 py-3" style="background-color: white;">
        <h3 style="color: #00a6ff; text-align: center; font-size: larger; font-weight: 500;">
          Sie haben noch keine Termine angefragt
        </h3>
      </v-card>
    </div>
    <div v-for="event in events" v-else :key="event.id">
      <v-card class="mb-5 mt-3 mx-3 pa-4" style="background-color: white;">
        <p>
          <i class="fa-regular fa-paper-plane mr-2"/><b class="title">Angefragt am</b><br>
          {{ convertDateTime(event.createdAt) }} Uhr
        </p>
        <v-divider/>
        <p>
          <i class="fa-solid fa-list-check mr-2"/><b class="title">Kategorien</b><br>
          <span v-for="(category, index) in event.cart" :key="category.id">
            {{ category.name }}<span v-if="index < event.cart.length - 1">, </span>
          </span>
        </p>
        <v-divider/>
        <p>
          <i class="fa-solid fa-calendar-day mr-2"/><b class="title">Wunschtermin</b><br>
          {{ convertDateTime(event.dateDesired) }} Uhr oder {{ convertDateTime(event.dateAlternative) }} Uhr
        </p>
        <v-divider/>
        <p>
          <i class="fa-solid fa-map-location-dot mr-2"/><b class="title">Adresse</b><br>
          {{ event.customerCompany }} {{ event.customerTitle }} {{ event.customerFirstname }} {{ event.customerLastname }},
          {{ event.customerStreet }} {{ event.customerHouse }},
          {{ event.customerZip }} {{ event.customerPlace }}
        </p>
        <v-divider/>
        <p v-if="event.orderStreet && event.orderHouse && event.orderZip && event.orderPlace">
          <i class="fa-solid fa-signs-post mr-2"/><b class="title">Reinigungsort</b><br>
          {{ event.orderStreet }} {{ event.orderHouse }},
          {{ event.orderZip }} {{ event.orderPlace }}
          <v-divider/>
        </p>
        <p>
          <i class="fa-solid fa-people-arrows mr-2"/><b class="title">Kontakt per</b><br>
          <span v-if="event.contactWhatsapp">Whatsapp</span>
          <span v-if="event.contactSms"><template v-if="event.contactWhatsapp">, </template>SMS</span>
          <span v-if="event.contactPhone"><template v-if="event.contactWhatsapp || event.contactSms">, </template>Telefon</span>
          <span v-if="event.contactEmail"><template
              v-if="event.contactWhatsapp || event.contactSms || event.contactPhone">, </template>E-Mail</span>
        </p>
        <v-divider/>
        <p>
          <i class="fa-solid fa-message mr-2"/><b class="title">Nachricht</b><br>
          {{ event.notification }}
        </p>
      </v-card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AccountEvents",
  computed: {
    ...mapGetters([
      'loadingEvents',
      'events'
    ])
  },
  methods: {
    convertDateTime(dateTime) {
      const date = new Date(dateTime);
      return `${date.toLocaleDateString()}, ${date.toLocaleTimeString(
          'de-DE', {hour: '2-digit', minute: '2-digit'}
      )}`;
    }
  },
  mounted() {
    this.$store.dispatch('getEvents');
  }
}
</script>