import {mutations} from "./mutations";
import {actions} from "./actions";
import {getters} from "./getters";

const state = {
    loadingUser: false,
    errorUser: false,
    errorUserDelete: false,
    loadingRegister: false,
    errorRegister: false,
    registered: false,
    loadingLogin: false,
    errorLogin: false,
    loadingResetPassword: false,
    errorResetPassword: false,
    jwt: null,
    user: null
}

const userModule = {
    state,
    mutations,
    actions,
    getters
}

export default userModule;