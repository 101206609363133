import * as types from "./mutation-types";
import axios from "axios";

export const actions = {
    getEvents({commit, rootGetters}) {
        const user = rootGetters.user;
        if (user) {
            commit([types.UPDATE_ERROR_EVENTS], false);
            commit(types.UPDATE_LOADING_EVENTS, true);
            axios.post(`/api/events`, {userId: user.id}, {
                headers: {
                    "x-auth-token": rootGetters.jwt
                }
            })
                .then(response => {
                    if (response.data.ok) {
                        commit(types.CREATE_EVENTS, response.data.events);
                    } else {
                        commit([types.UPDATE_ERROR_EVENTS], response.data.error);
                    }
                    commit(types.UPDATE_LOADING_EVENTS, false);
                })
                .catch(() => commit(types.UPDATE_LOADING_EVENTS, false));
        }
    },
    createEvent({commit, rootGetters}, payload) {
        commit([types.UPDATE_ERROR_EVENTS], false);
        commit(types.UPDATE_LOADING_EVENT, true);
        axios.post(`/api/event`,
            {
                userId: rootGetters.user ? rootGetters.user.id : null,
                cart: payload.cart,
                dateDesired: payload.dateDesired,
                dateAlternative: payload.dateAlternative,
                address: payload.address,
                whatsapp: payload.whatsapp,
                sms: payload.sms,
                phone: payload.phone,
                email: payload.email,
                notification: payload.notification
            },
            {
                headers: {
                    "x-auth-token": rootGetters.jwt
                }
            })
            .then(response => {
                if (response.data.ok) {
                    commit(types.CREATE_EVENT, response.data.event);
                } else {
                    commit([types.UPDATE_ERROR_EVENTS], response.data.error);
                }
                commit(types.UPDATE_LOADING_EVENT, false);
            })
            .catch(() => commit(types.UPDATE_LOADING_EVENT, false));
    }
}