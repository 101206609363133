<template>
  <div id="home">
    <div class="home-header">
      <div class="wrapper">
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
        <div><span class="dot"></span></div>
      </div>
      <v-container>
        <v-row class="ma-0">
          <v-col class="d-flex justify-center align-center" cols="12" md="6">
            <v-img :src="require('@/assets/Logo-.png')" style="height: 25vh"/>
          </v-col>
          <v-col class="d-flex flex-column justify-center align-center" style="color: white" cols="12" md="6">
            <h2 class="text-shadow2" v-if="company.name">{{ company.name }}</h2>
            <h2 class="text-shadow2" v-if="company.address1">{{ company.address1 }}</h2>
            <h2 class="text-shadow2" v-if="company.address2">{{ company.address2 }}</h2>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="home-text">
      <v-container>
        <v-row class="ma-0">
          <v-col class="pt-16 text-center" cols="12">
            <template v-if="user">
              <h1 class="">Herzlich Willkommen
                <span v-if="user.title === 'Frau' && user.lastname || user.title === 'Herr' && user.lastname">
                {{ user.title }} {{ user.lastname }}
                </span>
              </h1>
              <h2 class="">Sehen Sie sich unsere Preisrechner an und berechnen Sie Ihr Reinigungspreis!</h2>
              <h1 class="text-center text-shadow2" style="color: #00a6ff;">
                100%
              </h1>
              <h2 class="text-center text-shadow2 mb-10" style="color: #00a6ff;">
                KOSTENLOS und UNVERBINDLICH
              </h2>
            </template>

            <template v-else>
              <h1 class="">Herzlich Willkommen auf</h1>
              <h1 class="my-3 company-text-shadow">{{ company.name }}</h1>
              <h2 class="mb-3">Berechnen Sie Ihr Reinigungspreis!</h2>
              <h1 class="text-center text-shadow2" style="color: #00a6ff;">
                100%
              </h1>
              <h2 class="text-center text-shadow2 mb-10" style="color: #00a6ff;">
                KOSTENLOS und UNVERBINDLICH
              </h2>

              <v-row>
                <v-col cols="12">
                  <v-btn class="w-100 btn-login animation text-shadow2" @click="showModalLogin">
                    <i class="fa-solid fa-user-check  pe-2"></i>Anmelden / Registrieren
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="home-cards mb-5">
      <v-container>
        <v-row class="ma-0">
          <v-col cols="12" md="4" sm="6">
            <v-card class="mx-auto" width="100%">
              <template v-slot:title>
                <h4 class="text-pre-line text-center text-shadow2" style="font-weight: 400;">Einzugsgebiete</h4>
                <i class="fa-solid fa-map-location-dot text-shadow2"/>
              </template>
              <v-card-text class="background-maps">
                <ul>
                  <li><i class="fa-solid fa-hashtag mr-2"/>Ort 1.</li>
                  <li><i class="fa-solid fa-hashtag mr-2"/>Ort 2.</li>
                  <li><i class="fa-solid fa-hashtag mr-2"/>Ort 3.</li>
                  <li><i class="fa-solid fa-hashtag mr-2"/>Ort 4.</li>
                  <li><i class="fa-solid fa-hashtag mr-2"/>Ort 5.</li>
                  <li><i class="fa-solid fa-hashtag mr-2"/>Ort 6.</li>
                  <li><i class="fa-solid fa-hashtag mr-2"/>Ort 7.</li>
                  <li><i class="fa-solid fa-hashtag mr-2"/>Ort 8.</li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-card class="mx-auto" width="100%">
              <template v-slot:title>
                <h4 class="text-pre-line text-center text-shadow2" style="font-weight: 400;">Preisrechner</h4>
                <i class="fa-solid fa-calculator text-shadow2"/>
              </template>
              <v-card-text class="background-calculator">
                <ul>
                  <li v-for="category in categories" :key="category.id">{{ category.name }}</li>
                </ul>
                <v-btn class="btn-primary animation" @click="$router.push('/projects')"><i
                    class="fa-solid fa-calculator calculator mr-2"/>Preis berechnen !
                </v-btn>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4">
            <v-card class="mx-auto" width="100%">
              <template v-slot:title>
                <h4 class="text-pre-line text-center text-shadow2" style="font-weight: 400;">Kontakt</h4>
                <i class="fa-regular fa-comments text-shadow2"/>
              </template>
              <v-card-text class="background-meeting">
                <p class="mb-2" v-if="company.workingdays"><i class="fa-solid fa-clock mr-2"/>{{ company.workingdays }} {{ company.workingtime }}</p>
                <template v-if="company.phone">
                  <p><i class="fa-solid fa-phone phone mr-2 mb-2"/>{{ company.phone }}</p>
                  <p><i class="fa-solid fa-at mr-2 mb-2"/>{{ company.mailFrom }}</p>

                  <v-btn :href="`tel:${company.phone}`" class="btn-primary call animation">
                    <i class="fa-solid fa-phone me-2"/>Anrufen
                  </v-btn>
                  <v-btn :href="`https://wa.me/${company.whatsapp}`" class="btn-primary animation">
                    <i class="fa-brands fa-whatsapp me-2"/>Nachricht Senden
                  </v-btn>
                  <v-btn class="btn-primary animation at" @click="$router.push('/company')">
                    <i class="fa-solid fa-at at mr-2"/>Kontaktformular
                  </v-btn>
                </template>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="mb-15">
      <v-container>
        <v-col cols="12">
          <v-col id="appInstall" cols="12">
            <v-expansion-panels multiple>
              <v-expansion-panel class="elevation-5">
                <v-expansion-panel-title>
                  App-Installation
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                  <AppInstall/>
                </v-expansion-panel-text>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
          <v-btn
              class="share-button"
              elevation="5"
              rounded="pill"
              variant="outlined"
              block
              href="whatsapp://send?text=https://app.webdesign-38.de/">
            <i class="fa-brands fa-square-whatsapp whatsapp  mr-2"/>Teilen
          </v-btn>
        </v-col>
      </v-container>
    </div>
    <ModalLogin ref="ModalLogin"/>
    <Toast ref="Toast"/>
  </div>
</template>

<script>
import ModalLogin from "@/components/modal/Login";
import Toast from '@/components/modal/Toast';
import {mapGetters} from "vuex";
import AppInstall from "@/components/modal/Web-App/AppInstall";

export default {
  name: "HomeView",
  components: {
    AppInstall,
    ModalLogin,
    Toast
  },
  computed: {
    ...mapGetters([
      'user',
      'company',
      'categories'
    ])
  },
  methods: {
    showModalLogin() {
      this.$refs.ModalLogin.setState(true);
    }
  }
}
</script>