import * as types from "./mutation-types";
import axios from "axios";

export const actions = {
    async register({commit}, payload) {
        commit([types.UPDATE_ERROR_REGISTER], false);
        commit([types.UPDATE_LOADING_REGISTER], true);
        let jwt = null;
        await axios.post(`/api/auth/signup`, payload)
            .then(response => {
                if (response.data.ok) {
                    localStorage.setItem('jwt', response.data.token);
                    commit([types.UPDATE_JWT], response.data.token);
                    jwt = response.data.token;
                    commit([types.UPDATE_REGISTERED], true);
                } else {
                    commit([types.UPDATE_ERROR_REGISTER], response.data.error);
                }
                commit([types.UPDATE_LOADING_REGISTER], false);
            })
            .catch(() => commit([types.UPDATE_LOADING_REGISTER], false));
        if (jwt) {
            await axios.get(`/api/user`, {
                headers: {
                    "x-auth-token": jwt
                }
            })
                .then(response => {
                    if (response.data.ok) {
                        commit([types.UPDATE_USER], response.data.user);
                    } else {
                        commit([types.UPDATE_ERROR_LOGIN], response.data.error);
                    }
                    commit([types.UPDATE_LOADING_LOGIN], false);
                })
                .catch(() => {
                    localStorage.removeItem('jwt');
                    commit([types.UPDATE_LOADING_LOGIN], false);
                });
        }
    },
    resetRegistered({commit}) {
        commit([types.UPDATE_REGISTERED], false);
    },
    resetErrorRegister({commit}) {
        commit([types.UPDATE_ERROR_REGISTER], false);
    },
    async login({commit}, payload) {
        commit([types.UPDATE_ERROR_LOGIN], false);
        commit([types.UPDATE_LOADING_LOGIN], true);
        let jwt = null;
        if (payload.jwt) {
            commit([types.UPDATE_JWT], payload.jwt);
            jwt = payload.jwt;
        } else {
            await axios.post(`/api/auth`, payload)
                .then(response => {
                    if (response.data.ok) {
                        localStorage.setItem('jwt', response.data.token);
                        commit([types.UPDATE_JWT], response.data.token);
                        jwt = response.data.token;
                    } else {
                        commit([types.UPDATE_ERROR_LOGIN], response.data.error);
                        commit([types.UPDATE_LOADING_LOGIN], false);
                    }
                })
                .catch(() => commit([types.UPDATE_LOADING_LOGIN], false));
        }
        if (jwt) {
            await axios.get(`/api/user`, {
                headers: {
                    "x-auth-token": jwt
                }
            })
                .then(response => {
                    if (response.data.ok) {
                        commit([types.UPDATE_USER], response.data.user);
                    } else {
                        commit([types.UPDATE_ERROR_LOGIN], response.data.error);
                    }
                    commit([types.UPDATE_LOADING_LOGIN], false);
                })
                .catch(() => {
                    localStorage.removeItem('jwt');
                    commit([types.UPDATE_LOADING_LOGIN], false);
                });
        }
    },
    logout({commit}) {
        commit([types.UPDATE_ERROR_LOGIN], false);
        localStorage.removeItem('jwt');
        commit([types.UPDATE_JWT], null);
        commit([types.UPDATE_USER], null);
    },
    resetErrorLogin({commit}) {
        commit([types.UPDATE_ERROR_LOGIN], false);
    },
    updateUser({commit, rootGetters}, payload) {
        commit([types.UPDATE_ERROR_USER], false);
        commit([types.UPDATE_LOADING_USER], true);
        return axios.put(`/api/user`, payload, {
            headers: {
                "x-auth-token": rootGetters.jwt
            }
        })
            .then(response => {
                if (response.data.ok) {
                    commit([types.UPDATE_USER], response.data.user);
                } else {
                    commit([types.UPDATE_ERROR_USER], response.data.error);
                }
                commit([types.UPDATE_LOADING_USER], false);
                return response.data.ok;
            })
            .catch(() => commit([types.UPDATE_LOADING_USER], false));
    },
    deleteUser({commit, rootGetters}, payload) {
        commit([types.UPDATE_ERROR_USER_DELETE], false);
        commit([types.UPDATE_LOADING_USER], true);
        return axios.post(`/api/user/delete`, payload, {
            headers: {
                "x-auth-token": rootGetters.jwt
            }
        })
            .then(response => {
                if (response.data.ok) {
                    localStorage.removeItem('jwt');
                    commit([types.UPDATE_JWT], null);
                    commit([types.UPDATE_USER], null);
                } else {
                    commit([types.UPDATE_ERROR_USER_DELETE], response.data.error);
                }
                commit([types.UPDATE_LOADING_USER], false);
                return response.data.ok;
            })
            .catch(() => commit([types.UPDATE_LOADING_USER], false));
    },
    requestResetPassword({commit}, payload) {
        commit([types.UPDATE_ERROR_RESET_PASSWORD], false);
        commit([types.UPDATE_LOADING_RESET_PASSWORD], true);
        return axios.post(`/api/auth/reset`, payload)
            .then(response => {
                if (!response.data.ok) {
                    commit([types.UPDATE_ERROR_RESET_PASSWORD], response.data.error);
                }
                commit([types.UPDATE_LOADING_RESET_PASSWORD], false);
                return response.data.ok;
            })
            .catch(() => commit([types.UPDATE_LOADING_RESET_PASSWORD], false));
    },
    submitResetPassword({commit}, payload) {
        commit([types.UPDATE_ERROR_RESET_PASSWORD], false);
        commit([types.UPDATE_LOADING_RESET_PASSWORD], true);
        return axios.put(`/api/auth/reset`, payload)
            .then(response => {
                if (!response.data.ok) {
                    commit([types.UPDATE_ERROR_RESET_PASSWORD], response.data.error);
                }
                commit([types.UPDATE_LOADING_RESET_PASSWORD], false);
                return response.data.ok;
            })
            .catch(() => commit([types.UPDATE_LOADING_RESET_PASSWORD], false));
    }
}