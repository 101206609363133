export const getters = {
    loadingUser: state => state.loadingUser,
    errorUser: state => state.errorUser,
    errorUserDelete: state => state.errorUserDelete,
    loadingRegister: state => state.loadingRegister,
    errorRegister: state => state.errorRegister,
    registered: state => state.registered,
    loadingLogin: state => state.loadingLogin,
    errorLogin: state => state.errorLogin,
    loadingResetPassword: state => state.loadingResetPassword,
    errorResetPassword: state => state.errorResetPassword,
    jwt: state => state.jwt,
    user: state => state.user
}