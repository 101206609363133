<template>
  <v-footer absolute class="navigation px-2">
    <v-row style="align-items: center;">
      <v-col class="nav-icon px-1">
        <router-link to="/" class="button" style="flex-direction: column;">
          <i class="fa-solid fa-house"/>
          <h6 style="font-weight: 400">Start</h6>
        </router-link>
      </v-col>
      <v-col class="nav-icon px-1">
        <router-link to="/company" class="button" style="flex-direction: column;">
          <i class="fa-solid fa-users"/>
          <h6 style="font-weight: 400">...Firma...</h6>
        </router-link>
      </v-col>
      <v-col class="nav-button px-1" style="flex-direction: column; align-items: center;">
        <v-btn
            icon=""
            @click="$router.push('/projects')"
            :class="configuratorActive ? 'active':''"
        >
          <i class="fa-solid fa-calculator"/>
        </v-btn>
        <h6 style="font-weight: 400;"
            :class="configuratorActive ? 'active':'white'"
            class="text">
          Preisrechner
        </h6>
      </v-col>
      <v-col class="nav-icon px-1" style="flex-direction: column;">
        <a
            v-if="!user" href=""
            @click.prevent.stop="showModalLogin"
            class="button" style="flex-direction: column;"
        >
          <i class="fa-solid fa-arrow-right-to-bracket"/>
          <h6 style="font-weight: 400">Anmelden</h6>
        </a>

        <a
            @click.prevent.stop="showModalAccount"
            href=""
            v-if="user"
            class="button"
            style="flex-direction: column;"
        >
          <i class="fa-solid fa-user"/>
          <h6 style="font-weight: 400">Account</h6>
        </a>
      </v-col>
      <v-col class="nav-icon px-1 pb-4" style="flex-direction: column;">
        <router-link to="/info" class="button" style="flex-direction: column;">
          <i class="fa-solid fa-info"/>
          <h6 style="font-weight: 400">info</h6>
        </router-link>
      </v-col>
    </v-row>
    <ModalLogin ref="ModalLogin"/>
    <ModalAccount ref="ModalAccount"/>
  </v-footer>
</template>

<script>
import ModalLogin from '@/components/modal/Login';
import ModalAccount from '@/components/modal/Account';
import {mapGetters} from "vuex";

export default {
  name: 'AppNavigation',
  components: {
    ModalLogin,
    ModalAccount

  },
  computed: {
    getRoute() {
      return this.$route
    },
    configuratorActive() {
      switch (this.$route.path) {
        case '/projects':
        case '/configurator/categories':
        case '/configurator/elements':
        case '/calculation':
        case '/checkout':
          return true;
        default:
          return false;
      }
    },
    ...mapGetters([
      'user'
    ])
  },
  methods: {
    showModalLogin() {
      this.$refs.ModalLogin.setState(true);
    },
    showModalAccount() {
      this.$refs.ModalAccount.setState(true);
    }
  },
  data() {
    return {
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
    }
  },
}
</script>