import * as types from "./mutation-types";

export const mutations = {
    [types.UPDATE_LOADING_USER](state, payload) {
        state.loadingUser = payload;
    },
    [types.UPDATE_ERROR_USER](state, payload) {
        state.errorUser = payload;
    },
    [types.UPDATE_ERROR_USER_DELETE](state, payload) {
        state.errorUserDelete = payload;
    },
    [types.UPDATE_LOADING_REGISTER](state, payload) {
        state.loadingRegister = payload;
    },
    [types.UPDATE_ERROR_REGISTER](state, payload) {
        state.errorRegister = payload;
    },
    [types.UPDATE_REGISTERED](state, payload) {
        state.registered = payload;
    },
    [types.UPDATE_LOADING_LOGIN](state, payload) {
        state.loadingLogin = payload;
    },
    [types.UPDATE_ERROR_LOGIN](state, payload) {
        state.errorLogin = payload;
    },
    [types.UPDATE_LOADING_RESET_PASSWORD](state, payload) {
        state.loadingResetPassword = payload;
    },
    [types.UPDATE_ERROR_RESET_PASSWORD](state, payload) {
        state.errorResetPassword = payload;
    },
    [types.UPDATE_JWT](state, payload) {
        state.jwt = payload;
    },
    [types.UPDATE_USER](state, payload) {
        state.user = payload;
    }
}