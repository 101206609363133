<template>
  <div>
    <h1>Impressum</h1>
    <br>
    <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
    <p>{{ company.name }}<br />
      {{ company.address1 }}<br />
      {{ company.address2 }}<br />
    </p>

    <p>Handelsregister: HRB 999999<br />
      Registergericht: Amtsgericht Musterstadt</p>

    <p><strong>Vertreten durch:</strong><br />
      Dr. Harry Mustermann<br />
      Luise Beispiel</p>

    <p><strong>Vorsitzender des Aufsichtsrats:</strong><br />
      Richard Amtmann</p>
    <br>
    <h2>Kontakt</h2>
    <p>Telefon: +49 (0) 123 44 55 66<br />
      Telefax: +49 (0) 123 44 55 99<br />
      E-Mail: mustermann@musterfirma.de</p>
    <br>
    <h2>Umsatzsteuer-ID</h2>
    <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
      DE999999999</p>
    <br>
    <h2>Angaben zur Berufs&shy;haftpflicht&shy;versicherung</h2>
    <p><strong>Name und Sitz des Versicherers:</strong><br />
      Beispiel Versicherung AG<br />
      Musterweg 10<br />
      90210 Musterstadt</p>
    <p><strong>Geltungsraum der Versicherung:</strong><br />Deutschland</p>
    <br>
    <h2>EU-Streitschlichtung</h2>
    <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
    <br>
    <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
    <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>

    <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "LegalImprint",
  computed: {
    ...mapGetters([
      'company'
    ])
  }
}
</script>