import {mutations} from "./mutations";
import {actions} from "./actions";
import {getters} from "./getters";

const state = {
    loadingCompany: false,
    errorCompany: null,
    company: {
        name: null,
        address1: null,
        address2: null,
        phone: null,
        workingtime: null,
        workingdays: null,
        mailFrom: null,
        vat: null,
        fee: null,
        minTotalPrice: null
    }
}

const companyModule = {
    state,
    mutations,
    actions,
    getters
}

export default companyModule;