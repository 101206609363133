export const UPDATE_LOADING_USER = 'UPDATE_LOADING_USER';
export const UPDATE_ERROR_USER = 'UPDATE_ERROR_USER';
export const UPDATE_ERROR_USER_DELETE = 'UPDATE_ERROR_USER_DELETE';
export const UPDATE_LOADING_REGISTER = 'UPDATE_LOADING_REGISTER';
export const UPDATE_ERROR_REGISTER = 'UPDATE_ERROR_REGISTER';
export const UPDATE_REGISTERED = 'UPDATE_REGISTERED';
export const UPDATE_LOADING_LOGIN = 'UPDATE_LOADING_LOGIN';
export const UPDATE_ERROR_LOGIN = 'UPDATE_ERROR_LOGIN';
export const UPDATE_LOADING_RESET_PASSWORD = 'UPDATE_LOADING_RESET_PASSWORD';
export const UPDATE_ERROR_RESET_PASSWORD = 'UPDATE_ERROR_RESET_PASSWORD';
export const UPDATE_JWT = 'UPDATE_JWT';
export const UPDATE_USER = 'UPDATE_USER';