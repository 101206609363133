import * as types from "./mutation-types";
import axios from "axios";

export const actions = {
    getCompany({commit}) {
        commit([types.UPDATE_ERROR_COMPANY], false);
        commit(types.UPDATE_LOADING_COMPANY, true);
        axios.get(`/api/company`)
            .then(response => {
                if (response.data.ok) {
                    commit(types.UPDATE_COMPANY, response.data.company);
                } else {
                    commit([types.UPDATE_ERROR_COMPANY], response.data.error);
                }
                commit(types.UPDATE_LOADING_COMPANY, false);
            })
            .catch(() => commit(types.UPDATE_LOADING_COMPANY, false));
    }
}